@font-face {
    font-family: 'MontserratRegular';
    src: url('../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.navbar-main {
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    position: fixed;
    height: 123px;
    display: flex;
    align-items: center;
    background-color: rgba(255, 255, 255, 0);
    
    z-index: 8;
    transition: all 0.3s ease;
}

.show-white{
    background-color: white;
}

.scrolled-navbar{
    background-color: white;
    height: 73px;

}



.scrolled-nav{
    background-color: black !important;
}

.navbar-row {
    display: flex;
    justify-content: space-between;
    width: 90%;
    margin: 0 auto;

}

.navbar-left{
    display: flex;
    align-items: center;
    justify-content: center;
}

.navbar-right{
    display: flex;
    align-items: center;
    justify-content: center;
}

.logo-wrapper{
    height: 100%;
    display: inline-block; 
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.the-logo{
    width: 250px;
    transition: all 0.6s ease;
    cursor: pointer;
}

.scrolled-logo{
    width: 200px;
}

.navigations-row{
    display: flex;
    align-items: center;
    justify-content: center;
    display: flex;
}

.navigation{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(200px);
    transition: all 0.6s ease;
}

.navigation:hover{
    color: #8C0303;
}

.navigation2{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(200px);
    transition: all 0.6s ease;
    margin-left: 32px;
}

.navigation2:hover{
    color: #8C0303;
}


.navigation3{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(200px);
    transition: all 0.6s ease;
    margin-left: 32px;

}

.navigation3:hover{
    color: #8C0303;
}


.navigation4{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(200px);
    transition: all 0.6s ease;
    margin-left: 32px;

}

.navigation4:hover{
    color: #8C0303;
}


.navigation5{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    cursor: pointer;
    transform: translateY(200px);
    transition: all 0.6s ease;
    margin-left: 32px;

}

.navigation5:hover{
    color: #8C0303;
}



.move-up{
    transform: translateY(0px);

}

.menu-icon {
    width: 20px;
    height: 12px;
    display: none;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    margin-left: 20px;
    cursor: pointer;
    transition: transform 0.3s ease;
}



.menu-bar {
    width: 20px;
    height: 1px;
    background-color: white;
    transition: transform 0.3s ease;

}



.bar1 {
    transform: rotate(45deg) translate(0px, 8px);
    background-color: black;

}

.bar2 {
    transform: rotate(-45deg) translate(0px, -8px);
    background-color: black;

}

.hidden-menu {
    display: block;
    height: 200vh;
    width: 100%;
    background-color: white;
    position: fixed;
    top: 123px;
    left: 100%;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.scrolled-hidden{
    top: 73px;


}

.show-menu {
    left: 0%;
    z-index: 999;
}

.hidden-menu {
    display: flex;
}

.scrolled-navigation{
    color: black;
    transform: translateY(0px);

}

.navigations-wrapper{
    width: 90%;
    margin: 0 auto;
}

.open-navigation{
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(2rem, 1.3169rem + 3.9035vw, 7.5625rem);
    margin: 0;
    margin-top: 24px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.open-navigation:hover{
    color: #8C0303;
}

@media screen and (max-width: 1000px) {
    .navigations-row{
        display: none;
    }

    

    
    .menu-icon {
        
        display: flex;
        
    }

    
    .the-logo{
        width: 150px;
        transition: all 0.6s ease;
        cursor: pointer;
    }
    
    .scrolled-logo{
        width: 150px;
    }

}