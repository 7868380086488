@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.references-main{
    width: 70%;
    margin: 0 auto;
    margin-top: 121px;
}

.references-row{
    display: flex;
    margin-bottom: 64px;
}

.references-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.references-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-height: 600px;
    overflow: hidden; /* Ensures that the image does not bleed outside the container */

}

.reference-img{
    width: 100%;
    height: 100%;
    object-fit: cover; /* This makes the image cover the available space without losing its aspect ratio */

}

.refmargin{
    margin-bottom: 64px;
}

.space-wrapper{
    width: 80%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
}

.reimg-wrapper{
    width: 100%;
    margin-top: 24px;
}

.upper-header{
    text-align: left;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);
}

.upper-p{
    text-align: left;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(0.875rem, 0.7835rem + 0.2927vw, 1.25rem);
}

.bold{
    font-family: 'MontserratBold';

}

.ref-down{
    margin-top: 20px;
}

.scalelogo{
    width: 70%;
}

@media screen and (max-width: 1400px) {
    .references-main{
        width: 90%;
        margin: 0 auto;
    }
}



@media screen and (max-width: 1000px) {
    .references-main{
        width: 90%;
        margin: 0 auto;
        margin-top: 64px;
    }

    .references-row{
        display: flex;
        flex-direction: column;
    }

    .references-left{
        width: 100%;
        
    }
    
    .references-right{
        width: 100%;
        
    }

    .space-wrapper{
        width: 100%;
        margin-bottom: 32px;
    }

    .ref-down{
        margin-top: 20px;
    }

    .country-wrapper{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: 0 auto;
    }
}