@font-face {
  font-family: 'MontserratRegular';
  src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
  font-family: 'MontserratLight';
  src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
  font-family: 'MontserratMedium';
  src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
  font-family: 'MontserratBold';
  src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
  font-family: 'MontserratBlack';
  src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.ekipa-main {
  width: 70%;
  margin: 0 auto;
  margin-top: 121px;
}

/* HTML: <div class="loader"></div> */

.loading-message{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
  padding-bottom: 3rem;
}


.loader {
  width: 25px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid black;
  animation:
    l20-1 0.8s infinite linear alternate,
    l20-2 1.6s infinite linear;
}
@keyframes l20-1{
   0%    {clip-path: polygon(50% 50%,0       0,  50%   0%,  50%    0%, 50%    0%, 50%    0%, 50%    0% )}
   12.5% {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100%   0%, 100%   0%, 100%   0% )}
   25%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 100% 100%, 100% 100% )}
   50%   {clip-path: polygon(50% 50%,0       0,  50%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   62.5% {clip-path: polygon(50% 50%,100%    0, 100%   0%,  100%   0%, 100% 100%, 50%  100%, 0%   100% )}
   75%   {clip-path: polygon(50% 50%,100% 100%, 100% 100%,  100% 100%, 100% 100%, 50%  100%, 0%   100% )}
   100%  {clip-path: polygon(50% 50%,50%  100%,  50% 100%,   50% 100%,  50% 100%, 50%  100%, 0%   100% )}
}
@keyframes l20-2{ 
  0%    {transform:scaleY(1)  rotate(0deg)}
  49.99%{transform:scaleY(1)  rotate(135deg)}
  50%   {transform:scaleY(-1) rotate(0deg)}
  100%  {transform:scaleY(-1) rotate(-135deg)}
}

.ekipa-row {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ekipa-row {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center; /* Center items horizontally */
  align-items: center;
  width: 50%;
  /* Adjust as per your layout needs */
  margin: 0 auto;
}

.ekipa-circle {
  background-size: cover;
  background-position: center;
  border-radius: 50%;
  /* Makes the divs circular */
  width: calc(20% - 40px);
  /* Adjust the subtraction value based on your desired margin */
  height: 0;
  padding-top: calc(20% - 40px);
  /* Same value as width to maintain aspect ratio */
  margin: 20px;
  /* Half of the subtracted value for equal spacing on both sides */
  cursor: pointer;
  transition: transform 0.3s ease;

}

.chosen {
  width: 400px;
  height: 400px;
  padding-top: 0;
  /* Same value as width to maintain aspect ratio */

}

@keyframes blink {
  0%, 100% { transform: scale(1); }
  50% { transform: scale(0); }
}

.blink {
  animation: blink 0.5s; /* Run the blink animation */
}

.bw {
  filter: grayscale(100%);
  transition: all 0.3s ease;

}

.bw:hover {
  filter: grayscale(0%);
  transition: all 0.3s ease;

}

.chosen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.chosen-image {
  /* Style as needed */
  width: 100%;
  /* Example width, adjust as necessary */
  height: auto;
  /* Adjust based on your design */
}

.bigger-circle {
  width: 50%;
  margin: 0 auto;
  background-color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;


}



@keyframes borderChange {
  0% {
    border-radius: 30% 70% 56% 44% / 61% 32% 68% 39%;
  }
  33% {
    border-radius: 19% 81% 56% 44% / 50% 32% 68% 50%;
  }
  66% {
    border-radius: 58% 42% 49% 51% / 50% 21% 79% 50%;
  }
  100% {
    border-radius: 30% 70% 56% 44% / 61% 32% 68% 39%;
  }
}

.bigger-circle::before {
  content: '';
  display: block;
  padding-top: 100%;
  /* This ensures the height is the same as the width */
}

.circle {
  width: 30px;
  height: 30px;
  background: #9EBE21;
  border-radius: 50%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  margin: auto;
  animation: circle 6s linear infinite;
}

.my-team {
  margin-bottom: 0;
}

@keyframes circle {
  0% {
    transform: rotate(0deg) translateX(320px) rotate(0deg);
  }

  100% {
    transform: rotate(360deg) translateX(320px) rotate(-360deg);
  }
}

.member-name {
  font-family: 'MontserratBold';
  font-weight: 400;
    font-style: normal;
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);
    margin: 0;
    margin-top: 6px;
}

.member-profession{
  font-family: 'MontserratRegular';
  font-weight: 400;
    font-style: normal;
    font-size: clamp(0.875rem, 0.7835rem + 0.2927vw, 1.25rem);
    margin: 0;
    margin-top: 12px;
}

.icons-row{
  display: flex;
  margin-top: 24px;

}

.icon-wrapper{
  width: 40px;
  height: 40px;
  cursor: pointer;
  background-color: white;
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px;
  border-radius: 4px;
}

.fas{
  color: black;
}


@media screen and (min-width: 1921px) {
  .bigger-circle {
    width: 40%;
    margin: 0 auto;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
  }
  
}

@media screen and (max-width: 1600px) {
  .bigger-circle {
    width: 60%;
    margin: 0 auto;
    background-color: white;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  
  
  }
}

@media screen and (max-width: 1400px) {
  .chosen {
    width: 320px;
    height: 320px;
    padding-top: 0;
    /* Same value as width to maintain aspect ratio */
  
  }

  .ekipa-row{
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .ekipa-main {
    width: 90%;
    margin: 0 auto;
    margin-top: 64px;
  }
  
  .my-team{
    margin-bottom: 24px;
  }

  .ekipa-row{
    width: 70%;
    margin-top: 32px;
  }

  .ekipa-circle {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    /* Makes the divs circular */
    width: calc(20% - 20px);
    /* Adjust the subtraction value based on your desired margin */
    height: 0;
    padding-top: calc(20% - 20px);
    /* Same value as width to maintain aspect ratio */
    margin: 10px;
    /* Half of the subtracted value for equal spacing on both sides */
    cursor: pointer;
    transition: transform 0.3s ease;
  
  }

  .chosen {
    width: 240px;
    height: 240px;
    padding-top: 0;
    /* Same value as width to maintain aspect ratio */
  
  }
  
}

@media screen and (max-width: 800px) {
  

  .ekipa-row{
    width: 80%;
    margin-top: 32px;
  }

  .ekipa-circle {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    /* Makes the divs circular */
    width: calc(20% - 20px);
    /* Adjust the subtraction value based on your desired margin */
    height: 0;
    padding-top: calc(20% - 20px);
    /* Same value as width to maintain aspect ratio */
    margin: 10px;
    /* Half of the subtracted value for equal spacing on both sides */
    cursor: pointer;
    transition: transform 0.3s ease;
  
  }

  .chosen {
    width: 240px;
    height: 240px;
    padding-top: 0;
    /* Same value as width to maintain aspect ratio */
  
  }
  
}

@media screen and (max-width: 600px) {
  

  .ekipa-row{
    width: 90%;
    margin-top: 32px;
  }

  .ekipa-circle {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    /* Makes the divs circular */
    width: calc(20% - 10px);
    /* Adjust the subtraction value based on your desired margin */
    height: 0;
    padding-top: calc(20% - 10px);
    /* Same value as width to maintain aspect ratio */
    margin: 5px;
    /* Half of the subtracted value for equal spacing on both sides */
    cursor: pointer;
    transition: transform 0.3s ease;
  
  }

  .chosen {
    width: 240px;
    height: 240px;
    padding-top: 0;
    /* Same value as width to maintain aspect ratio */
  
  }
  
}

@media screen and (max-width: 450px) {
  

  .ekipa-row{
    width: 100%;
    margin-top: 32px;
  }

  .ekipa-circle {
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    /* Makes the divs circular */
    width: calc(20% - 10px);
    /* Adjust the subtraction value based on your desired margin */
    height: 0;
    padding-top: calc(20% - 10px);
    /* Same value as width to maintain aspect ratio */
    margin: 5px;
    /* Half of the subtracted value for equal spacing on both sides */
    cursor: pointer;
    transition: transform 0.3s ease;
  
  }

  .chosen {
    width: 240px;
    height: 240px;
    padding-top: 0;
    /* Same value as width to maintain aspect ratio */
  
  }
  
}