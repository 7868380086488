@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.locations-main{
    width: 70%;
    margin: 0 auto;
}

.locations-row{
    display: flex;
    margin-top: 64px;
}

.locations-left{
    width: 50%;
    display: flex;
    flex-direction: column;
    
}

.locations-right{
    width: 50%;
    display: flex;
    flex-direction: column;
    
}

.subbmargins{
    margin-top: 64px;
}

.country-h{
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);

}

.location-img{
    width: 100%;
}

.country-wrapper{
    display: flex;
    flex-direction: column;
    width: 95%;
    margin: 0 auto;
}

.framec{
    border: 0;
    
}

.flag-img{
    width: 20%;
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2); /* Adjust the color and spread as needed */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-top: 12px;
}

.flag-img:hover {
    transform: translateY(-5px); /* Slightly raises the image on hover */
    box-shadow: 0 12px 20px rgba(0, 0, 0, 0.3); /* Increases shadow spread on hover for a "lifting" effect */
  }

  .icon-wrapperr{
    margin-right: 12px;
   
  }

  .pmargind{
    margin: 0;
    font-size: 24px;
    font-family: 'MontserratMedium';

  }

  .rrrr{
    margin-top: 24px;
  }

  .fab::before{
    color: black;
    transform: scale(2);
  }

  .mergecontact{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 64px;
    text-align: center;

  }

  @media screen and (max-width: 1400px) {
    .locations-main{
        width: 90%;
        margin: 0 auto;
    }
}


@media screen and (max-width: 1000px) {
    .locations-main{
        width: 90%;
        margin: 0 auto;
    }

    .locations-row{
        display: flex;
        flex-direction: column;
    }
    
    .locations-left{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }
    
    .locations-right{
        width: 100%;
        display: flex;
        flex-direction: column;
        
    }

    .country-h{
        margin-top: 32px;
    }
    
}