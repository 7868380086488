@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.expertise-main {
    width: 70%;
    margin: 0 auto;
}

.expertise-sub {
    text-align: center;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);
}

.expertise-p {
    text-align: center;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(0.875rem, 0.7835rem + 0.2927vw, 1.25rem);
}

@media screen and (max-width: 1400px) {
    .expertise-main{
        width: 80%;
        margin: 0 auto;
        margin-top: 64px;

      }
}

@media screen and (max-width: 1000px) {
    .expertise-main {
        width: 90%;
        margin: 0 auto;
    }
}