@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
  }
  
  @font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
  }
  
  @font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
  }
  
  @font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
  }
  
  @font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
  }

  .about-work-main{
    width: 70%;
    margin: 0 auto;
    margin-top: 121px;
  }

  .about-work-row{
    display: flex;
    width: 100%;
  }

  .about-left1{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .about-right1{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }

  .a-expertise-sub {
    text-align: left;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);
}

.a-expertise-p {
    text-align: left;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(0.875rem, 0.7835rem + 0.2927vw, 1.25rem);
}

.about-img{
    width: 100%;
}

.mobilni{
    display: none;
}

.about-content-wrapper{
    width: 80%;
    margin: 0 auto;
}

.bullet-row{
    display: flex;
    align-items: center;
    margin: 0;
}

.bullet{
    width: 8px;
    height: 8px;
    margin-right: 12px;
    background-color: #8c0202;
    border-radius: 50%;
    margin-bottom: 12px;

}

.pmargin{
    margin: 0;
    margin-bottom: 12px;
    font-family: 'MontserratMedium';

}

.btn-margin
{
    margin-top: 24px;
}

@media screen and (max-width: 1400px) {
    .about-work-main{
        width: 90%;
        margin: 0 auto;
        margin-top: 64px;

      }
}

  @media screen and (max-width: 1000px) {
    .about-work-main{
        width: 90%;
        margin: 0 auto;
        margin-top: 64px;

      }

      .about-work-row{
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 32px;
      }
    
      .about-left1{
        width: 100%;
        
      }
    
      .about-right1{
        width: 100%;
        
      }

      .mobilni{
        display: flex;
    }

    .desktopi{
        display: none;
    }

    .about-content-wrapper{
        width: 100%;
        margin: 0 auto;
    }
}