@font-face {
    font-family: 'MontserratRegular';
    src: url('../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.footer-main{
    padding-top: 12px;
    padding-bottom: 12px;

    width: 100%;
    background-color: black;
    transform: translateY(64px);
}

.rights-reserved{
    width: 100%;
    color: white;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    margin: 0 auto;
    text-align: center;
}

.first-footer-row{
    display: flex;
}

.footer-wrapper{
    width: 90%;
    margin: 0 auto;
}

.navigations-footer{
    color: white;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
}

.footer-nav{
    cursor: pointer;
    color: white;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
}

.footer-column{
    margin-right: 64px;
}

.row-of-footer-contacts{
    display: flex;
}

.FASSS{
    color: white !important;
}

.FABBB{
    color: white !important;

}

@media screen and (max-width: 1000px) {
    .first-footer-row{
        display: flex;
        flex-direction: column;
    }

    .footer-column{
        margin-right: 0px;
        margin-bottom: 32px;
    }
}