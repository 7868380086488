.css-1qzevvg{
    display: none !important;
}



.css-1fzpoyk{
    width: 50%;
    height: auto;
    overflow: hidden; /* Ensure that any excess part of the image is clipped */
    display: flex; /* This can help ensure the image is centered if needed */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
}

.css-1fzpoyk img{
    width: 100%;
    padding-top: 0%; /* 16:9 Aspect Ratio */
    position: relative;/* Make sure the image takes the full height of the container */
    object-fit: cover; /* This will cover the area, cropping the image if necessary */
}

.super-carousel-css {
    width: 100%;
    height: 300px;
    margin: 0 auto; /* Center the carousel */
    overflow: hidden;
}

@media screen and (min-width: 1921px) {
    .super-carousel-css {
        width: 100%;
        height: 400px;
        margin: 0 auto; /* Center the carousel */
    }
}

@media screen and (max-width: 1000px) {
    .css-1fzpoyk{
        width: 70%;
        height: auto;
        overflow: hidden; /* Ensure that any excess part of the image is clipped */
        display: flex; /* This can help ensure the image is centered if needed */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }
    
    .css-1fzpoyk img{
        width: 100%;
        padding-top: 0%; /* 16:9 Aspect Ratio */
        position: relative;/* Make sure the image takes the full height of the container */
        object-fit: cover; /* This will cover the area, cropping the image if necessary */
    }
}