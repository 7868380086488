@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.hero-main{
    width: 100%;
    height: 80vh;
    overflow: hidden;
}

.hero-swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide-bg {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.hero-shadows{
    width: 100%;
    height: 80vh;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0,1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 2s ease;
    box-shadow: inset 0px 5px 53px 3px rgba(0,0,0,0.75);
    z-index: 2;

}

.super-shadows{
    width: 100%;
    height: 100vh;
    position:fixed;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0,1);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 1s ease;
    z-index: 99;
}

.show-shadow{
    background-color: rgba(0, 0, 0,0.3);

}

.show-shadows{
    background-color: rgba(0, 0, 0,0.0);
    z-index: -1;
}

.text-wrapprer{
    display: inline-block; 
    overflow: hidden;
}

.hero-header{
    font-family: 'MontserratBlack';
    font-weight: 400;
    font-style: normal;
    color: white;
    opacity: 1;
    font-size: clamp(2.5rem, -0.625rem + 10vw, 5rem);
    margin: 0;
    transform: translateY(200px);
    transition: all 0.6s ease;
}



.hero-wrapper{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    
}

.hero-paragraph{
    font-family: 'MontserratBlack';
    font-weight: 400;
    font-style: normal;
    color: white;
    opacity: 1;
    font-size: clamp(1.25rem, 0.3125rem + 3vw, 2rem);
    margin: 0;
    margin-top: 12px;
    text-align: center;
    transform: translateY(200px);
    transition: all 0.6s ease;

}



.buttons-row{
    display: flex;
    flex-direction: row;
    margin-top: 64px;
}

.hero-button1{
    width: 175px;
    height: 48px;
    border: 1px solid white;
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 1);
    color: black;
    font-size: 14px;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    transform: translateY(200px);
    transition: all 0.6s ease;

}

.hero-button2{
    width: 175px;
    height: 48px;
    border: 1px solid white;
    border-radius: 0px;
    background-color: rgba(255, 255, 255, 0);
    color: white;
    font-size: 14px;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    transform: translateY(200px);
    transition: all 0.6s ease;
    margin-left: 32px;
}

.hero-button1:hover{
    background-color: rgba(255, 255, 255, 0);
    cursor: pointer;
    color: white;
}

.hero-button2:hover{
    background-color: rgba(255, 255, 255, 1);
    cursor: pointer;
    color: black;
}

.move-up{
    transform: translateY(0px);

}

@media screen and (max-width: 1000px) {
    .buttons-row{
        display: flex;
        flex-direction: column;
        margin-top: 48px;
    }

    .hero-button2{
        
        margin-left: 0px;
        margin-top: 12px;
    }
    
}
