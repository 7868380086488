@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.products-main {
    width: 70%;
    margin: 0 auto;
    margin-top: 128px;
}

.products-name {
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    text-align: center;
    color: #8c0202;
    font-size: clamp(2rem, 1.5122rem + 1.561vw, 4rem);
    margin-bottom: 64px;
    margin-top: 64px;

}

.btn-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 64px;
}

.content-divider {
    display: flex;
    width: 100%;
    margin-bottom: 121px;
}

.margindiv {
    width: 100%;
    height: 1px;
    margin-top: 121px;

}

.divide-left {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.divide-right {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.product-image {
    width: 90%;
    margin: 0 auto;
}

.show-more-btn {
    width: 175px;
    height: 48px;
    border: 1px solid #8c0202;
    border-radius: 0px;
    background-color: #8c0202;
    color: white;
    font-size: 14px;
    font-family: 'MontserratBold';
    font-weight: 400;
    font-style: normal;
    transition: all 0.6s ease;
    cursor: pointer;
}

.product-holder {
    width: 100%;
}

.dangerous-html {
    width: 90%;
    margin: 0 auto;
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(1rem, 0.878rem + 0.3902vw, 1.5rem);

}

.youtube-video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 Aspect Ratio */
    margin-bottom: 20px;
    /* Optional spacing between videos */
}

.youtube-video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

a {
    color: #9EBE21;
    font-weight: bold;
}

@media screen and (min-width: 1921px) {
    .content-divider {
        margin-bottom: 160px;
    }

    .margindiv {
        margin-top: 160px;

    }
}

@media screen and (max-width: 1400px) {
    .content-divider {
        margin-bottom: 80px;
    }

    .margindiv {
        margin-top: 80px;

    }
}

@media screen and (max-width: 1200px) {
    .products-main {
        width: 90%;
        margin: 0 auto;
        margin-top: 64px;

    }

    .content-divider {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .divide-left {
        width: 100%;
    }

    .divide-right {
        width: 100%;
    }

    .dangerous-html {
        width: 100%;
        margin: 0 auto;

    }

    .products-name {
        font-family: 'MontserratBold';
        font-weight: 400;
        font-style: normal;
        text-align: center;
        color: #8c0202;
        font-size: clamp(2rem, 1.5122rem + 1.561vw, 4rem);
        margin-bottom: 32px;

    }

    .product-image {
        width: 100%;
        margin: 0 auto;
    }


}


@media screen and (max-width: 600px) {
    .content-divider {
        margin-bottom: 20px;
    }

    .margindiv {
        margin-top: 20px;

    }

}

@media screen and (max-width: 500px) {
    .content-divider {
        margin-bottom: 0px;
    }

    .margindiv {
        margin-top: 0px;

    }
}