@font-face {
    font-family: 'MontserratRegular';
    src: url('../../../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}

.business-main {
    width: 80%;
    margin: 0 auto;
    text-align: left;
}

.businesssub{
    text-align: left;

}

.business-info{
    font-family: 'MontserratRegular';
    font-weight: 400;
    font-style: normal;
    font-size: clamp(0.875rem, 0.7835rem + 0.2927vw, 1.25rem);
    line-height: 2;
}

.boldovano{
    font-family: 'MontserratBold';

}

@media screen and (max-width: 1000px) {
    .business-main {
        width: 90%;
        margin: 0 auto;
    }
}