@font-face {
    font-family: 'MontserratRegular';
    src: url('../../Resources/Fonts/Montserrat-Regular.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratLight';
    src: url('../../Resources/Fonts/Montserrat-Light.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratMedium';
    src: url('../../Resources/Fonts/Montserrat-Medium.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBold';
    src: url('../../Resources/Fonts/Montserrat-Bold.ttf') format('woff2');
}

@font-face {
    font-family: 'MontserratBlack';
    src: url('../../Resources/Fonts/Montserrat-Black.ttf') format('woff2');
}



.loading-page-main{
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'MontserratMedium';
    font-weight: 400;
    font-style: normal;
    background-color: white;
    position: relative;
    overflow: hidden; /* Optional: Prevents scrollbars if the canvas overflows */


}



.loading-content-wrapper{
  width: 50%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  z-index: 9;
}

.logo-img{
  width: 30%;
  margin: 0 auto;
  margin-bottom: 32px;
}

.progress {
  fill: black;
  stroke-width: 50;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  cx: 37.5;
  cy: 37.5;
}

.center {
  background-color: white     ;
  fill: white;

}

text {
  fill: black;
  font-family: 'MontserratMedium';
    font-weight: 400;
    font-style: normal;
  font-size: 14px;
}

svg {
  background: transparent;
  border-radius: 50%;
}
.container {
  
}

@keyframes load {
  0% { width: 0; }
  100% { width: 100%; }
}

.shadows-div{
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000000;
  z-index: 2;
  opacity: 0;
  transition: all 0.3s ease;
  z-index: 13;
  pointer-events: none;
}

.shadowoff{
  opacity: 1;
}

*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { content: ''; display: table; }
.clearfix:after { clear: both; }

#demo-canvas {
  background: #ffffff;
}



section {
	padding: 1em;
	text-align: center;
}

.the-button-wrapper{
  margin-top: 64px;
  display: inline-block;
  overflow: hidden;

}

.goinbutton{
  transform: translateY(100px);
  transition: all 0.3s ease;
  width: 125px;
  height: 35px;
  border-radius: 0px;
  background-color: transparent;
  border: 1px solid black;
  color: black;
  font-family: 'MontserratMedium';
    font-weight: 400;
    font-style: normal;
    cursor: pointer;
    transition: all 0.3s ease;
}

.goinbutton:hover{
  background-color: black;
  color: white;

}

.showbutton{
  transform: translateY(0px);

}

@media screen and (max-width: 1000px) {
  .loading-content-wrapper{
    width: 90%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-img{
    width: 70%;
    margin: 0 auto;
    margin-bottom: 20px;
  }

  .the-button-wrapper{
    margin-top: 32px;
    display: inline-block;
    overflow: hidden;
  
  }
}